@tailwind base;

@tailwind components;

@tailwind utilities;

[x-cloak]{
  display:none;
}

svg {
    width:100%;
  }
  .wave {
    animation: wave 3s linear;
    animation-iteration-count:infinite;
    fill: #fbbf24;
  }

  #wave2 {
    animation-duration:5s;
    animation-direction: reverse;
    opacity: .6
  }
  #wave3 {
    animation-duration: 7s;
    opacity:.3;
  }
  @keyframes drop {
    0% {
      transform: translateY(80%);
      opacity:.6;
    }
    80% {
      transform: translateY(80%);
      opacity:.6;
    }
    90% {
      transform: translateY(10%) ;
      opacity:.6;
    }
    100% {
      transform: translateY(0%) scale(1.5);
      stroke-width:0.2;
      opacity:0;
    }
  }
  @keyframes wave {
    from {transform: translateX(0%);}
    to {transform: translateX(-100%);}
  }

  .form-control{
    @apply form-input w-full;
  }

/****** Form Input CLASSES **********/

.form-input, .form-textarea, .form-select {
    @apply appearance-none bg-white border border-gray-400 rounded-md py-2 px-3 text-base leading-normal transition duration-150 ease-in-out focus:outline-none focus:ring focus:ring-opacity-30 focus:ring-amber-500 focus:border-amber-500;
}

.form-select{
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-position: right .5rem center;
    background-size: 1.5em 1.5em;
    padding:.5rem 2.5rem .5rem .75rem;
    background-repeat: no-repeat;
}

/****** MIN-W CSS **********/
.min-w-64 {
  min-width: 16rem; /* 256px */
}
.max-w-80 {
  max-width: 20rem; /* 320px */
}